import { User } from 'Types/user';
import { DEALER_PERMISSIONS } from 'Utilities/consts';

import usePermission, { checkForPermission } from './usePermission';

export const checkForKycPermission = (user: User): boolean => checkForPermission(user, DEALER_PERMISSIONS.KYC);

const useKycPermission = (): boolean => usePermission(DEALER_PERMISSIONS.KYC);

export default useKycPermission;
